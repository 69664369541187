import { useMutation, UseMutationResult } from "react-query";
import ViolationError from "../utils/violationError";
import { fetch } from "../utils/dataAccess";

interface IRequestCodeResponse {
  data: {
    revenue?: number;
    website?: string;
  };
  success: boolean;
}

interface IVerifyBusinessInfoPostProps {
  revenue: number;
  dropship_product: boolean;
  auth_person: boolean;
}

interface IVerifyBusinessInfoOptionSegmentPostProps {
  segments: string[];
}
interface IVerifyBusinessInfoFundPurposePostProps {
  revenue: number;
  purposes: string[];
}

export const verifyBusinessInfo = async ({
  revenue,
  dropship_product,
  auth_person,
}: IVerifyBusinessInfoPostProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      monthly_revenue: revenue,
      dropship_product: dropship_product,
      auth_person: auth_person,
    },
  });

  return data;
};

export const verifyRevenueSegmentInfo = async ({
  segments,
}: IVerifyBusinessInfoOptionSegmentPostProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      segments: segments,
    },
  });

  return data;
};

export const verifyFundPurposeInfo = async ({
  revenue,
  purposes,
}: IVerifyBusinessInfoFundPurposePostProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      monthly_revenue: revenue,
      purposes: purposes,
    },
  });

  return data;
};

export const useVerifyBusinessInfoPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoPostProps
> => {
  return useMutation(verifyBusinessInfo);
};

export const useRevenueSegmentInfoPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoOptionSegmentPostProps
> => {
  return useMutation(verifyRevenueSegmentInfo);
};

export const useUpdateFundPurposePost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoFundPurposePostProps
> => {
  return useMutation(verifyFundPurposeInfo);
};
