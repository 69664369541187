import {
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { VerifyBusinessFormsExtendedType } from "./VerifyBusinessInfoForm";
import { VerifyBusinessPurposeValues } from "../../types/VerifyBusinessInfoValues";
import * as Yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useUpdateFundPurposePost } from "../../queries/businessInfo";
import ViolationError from "../../utils/violationError";
import { getRevenueOptionDetails } from "./VerifyBusinessInfoForm";
import { PATH_ONBOARDING } from "../Routes/paths";
const SHOW_ERROR_TIMEOUT_MS = 5000;

export const FundPurposes = [
  {
    id: 1,
    label: "pages.fund_purpose.marketing",
    value: "marketing",
  },
  {
    id: 2,
    label: "pages.fund_purpose.inventory_purchasing",
    value: "inventory_purchasing",
  },
  {
    id: 3,
    label: "pages.fund_purpose.improving_cashflow",
    value: "improving_cashflow",
  },
  {
    id: 4,
    label: "pages.fund_purpose.research_and_development",
    value: "research_and_development",
  },
  {
    id: 5,
    label: "pages.fund_purpose.company_expansion",
    value: "company_expansion",
  },
  {
    id: 6,
    label: "pages.fund_purpose.other",
    value: "other",
  },
];

const ChooseFundPurposeForm = ({
  handleChangePage,
  currentPage,
  updateEligibleRevenue,
}: VerifyBusinessFormsExtendedType) => {
  const { t } = useTranslation(["translation", "countries"]);
  const formikRef = useRef<FormikProps<VerifyBusinessPurposeValues>>(null);
  const { company } = useAuth();
  const [purposes, setPurposes] = useState<string[]>(
    company?.fundingPurposes ?? []
  );
  const [cachedFinancing, setCachedFinancing] = useState<number | null>(null);

  const { mutate: updateFundPurpose } = useUpdateFundPurposePost();

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), SHOW_ERROR_TIMEOUT_MS);
    }
  }, [error]);

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;
    if (event.target.checked) {
      setPurposes((prevItems) => [...prevItems, value]);
    } else {
      setPurposes((prevItems) => prevItems.filter((item) => item !== value));
    }
  };

  const possibleFinancing = getRevenueOptionDetails(
    company?.monthlyRevenue ?? 0
  )["possibleFinancing"];

  useEffect(() => {
    if (possibleFinancing !== 0) {
      setCachedFinancing(possibleFinancing);
    }
  }, [possibleFinancing]);

  return (
    <>
      <Stack
        direction={"column"}
        sx={{
          width: "100%",
          backgroundColor: "rgba(4, 226, 203, 0.04)",
          border: "1px solid #04E2CB",
          p: 4,
          borderRadius: "12px",
          mb: 4,
        }}
        spacing={5}
        alignItems={"center"}
      >
        <Typography variant="h3" component="h1">
          {t("pages.fund_purpose.great_news")}
        </Typography>
        <Typography variant="h5" component="h1">
          {t("pages.fund_purpose.eligible_message")}
        </Typography>
        <Typography variant="h2" component="h1">
          {company?.countryCode === "SA" ? "SAR" : "AED"}{" "}
          {cachedFinancing?.toLocaleString()}
          ,000.00*
        </Typography>
      </Stack>
      <Formik
        innerRef={formikRef}
        validateOnMount
        initialValues={
          {
            revenue: getRevenueOptionDetails(company?.monthlyRevenue ?? 0),
          } as VerifyBusinessPurposeValues
        }
        validationSchema={Yup.object().shape({
          revenue: Yup.object()
            .nullable()
            .required(t("error.company.monthly_revenue.required")),
        })}
        onSubmit={({ revenue }, { setSubmitting, resetForm }) => {
          if (purposes.length === 0) {
            purposes.push("other");
          }
          updateFundPurpose(
            {
              revenue: revenue.value as number,
              purposes: purposes,
            },
            {
              onError: (error) => {
                if (error instanceof ViolationError) {
                  setError(`${error.defaultErrorMsg}`);
                } else {
                  setError(`${error.message}`);
                }
              },
              onSuccess: (data) => {
                if (data.success) {
                  window.location.href = PATH_ONBOARDING.root;
                } else {
                  setError(t("error.unexpected"));
                }
              },
            }
          );
        }}
      >
        {({
          isValid,
          isSubmitting,
          touched,
          errors,
          values,
          setSubmitting,
        }) => (
          <Form>
            {error && (
              <Box sx={{ my: 3 }}>
                <FormHelperText error sx={{ textAlign: "left" }}>
                  {error}
                </FormHelperText>
              </Box>
            )}
            <Stack direction={"column"} spacing={1}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("pages.fund_purpose.fund_question")}
              </Typography>
              <FormGroup>
                <Grid container mb={4}>
                  {FundPurposes.map((ps) => {
                    const label = t(ps.label);

                    return (
                      <Grid item xs={6} key={ps.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "gray",
                                "&.Mui-checked": { color: "gray" },
                              }}
                              checked={purposes.includes(ps.value)}
                              onChange={handleCheckBoxChange}
                              name={ps.value}
                            />
                          }
                          label={label}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </FormGroup>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4}
            >
              <Button
                id="back-button"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => handleChangePage(currentPage - 1)}
              >
                {t("global.back")}
              </Button>
              <Button
                id="register-button"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size="1.2rem" sx={{ color: "#fff" }} />
                  ) : (
                    <></>
                  )
                }
              >
                {t("global.continue")}
              </Button>
            </Stack>
            <Stack>
              <Typography variant="caption">
                {t("pages.fund_purpose.fund_agreement")}
              </Typography>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChooseFundPurposeForm;
