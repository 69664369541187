import {
  Button,
  Card,
  Container,
  Stack,
  Typography,
  Box,
  LinearProgress,
  CircularProgress,
  Alert,
  alpha,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH_ONBOARDING } from "../Routes/paths";
import { Form, Formik } from "formik";
import { KycKybValues } from "../OnboardingVerification/KycKybForm";
import useAuth from "../../hooks/useAuth";
import { useKycKybPost } from "../../queries/kycKyb";
import * as Yup from "yup";
import { KycKybDocumentsValues } from "../../types/FormValues";
import {
  DEFAULT_CERTIFICATION_MIME_TYPES,
  DEFAULT_CONSENT_MIME_TYPES,
} from "../../config/config";
import { PrivateFile } from "../../types/PrivateFile";
import { UploadMultiFile } from "../Upload";
import ViolationError from "../../utils/violationError";
import PoWNotificationModal from "../OnboardingVerification/PoWNotificationModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

interface IconStatusProps {
  data: any;
  form_error: boolean;
}
export const IconStatus = ({ data, form_error }: IconStatusProps) => {
  if (form_error === true) return <WarningAmberOutlinedIcon color="warning" />;
  if (data && data.length) return <CheckCircleOutlineIcon color="success" />;
  else return <InfoOutlinedIcon color="info" />;
};

const AdditionalDocumentsKSA = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, company, setCompany } = useAuth();
  const countryCode = company?.countryCode;

  const { mutate: kycKybPost } = useKycKybPost();
  const [PoWModalOpened, setPoWModalOpened] = useState(false);

  if (countryCode !== "SA") {
    navigate(PATH_ONBOARDING.applicationOverview);
    return null;
  }

  if (company?.registrationNumber === "") {
    navigate(PATH_ONBOARDING.verification);
    return null;
  }

  const initialValuesKsa = {
    articlesOfAssociation:
      company?.kycKybDocuments?.articlesOfAssociation ?? [],
    companyConsent: company?.kycKybDocuments?.companyConsent ?? [],
    shareholderConsent: company?.kycKybDocuments?.shareholderConsent ?? [],
    vatCertificate: company?.kycKybDocuments?.vatCertificate ?? [],
    gosiCertificate: company?.kycKybDocuments?.gosiCertificate ?? [],
  } as KycKybValues;

  return (
    <Container maxWidth="lg" sx={{ my: 5, pr: 0 }} disableGutters>
      <Card sx={{ mb: 6, p: 4 }}>
        <Stack direction="column" spacing={9} justifyContent="space-between">
          <Box>
            <Typography variant="h4" sx={{ mb: 1.5 }}>
              {t("pages.deployment.heading")}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {t("pages.deployment.subheading_ksa")}
            </Typography>
          </Box>

          <Formik
            validateOnMount={true}
            initialValues={initialValuesKsa}
            validationSchema={Yup.object().shape({
              articlesOfAssociation: Yup.array()
                .max(20, () => {
                  return t(
                    "error.company.kyc_kyb_documents.articles_of_association.max",
                    {
                      limit: 20,
                    }
                  );
                })
                .mime(
                  Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
                  t("error.general.mime", {
                    types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(
                      ", "
                    ),
                  })
                ),
              companyConsent: Yup.array()
                .required(
                  t("error.company.kyc_kyb_documents.company_consent.required")
                )
                .mime(
                  Object.keys(DEFAULT_CONSENT_MIME_TYPES),
                  t("error.general.mime", {
                    types: Object.keys(DEFAULT_CONSENT_MIME_TYPES).concat(", "),
                  })
                ),
              shareholderConsent: Yup.array()
                .required(
                  t(
                    "error.company.kyc_kyb_documents.shareholder_consent.required"
                  )
                )
                .mime(
                  Object.keys(DEFAULT_CONSENT_MIME_TYPES),
                  t("error.general.mime", {
                    types: Object.keys(DEFAULT_CONSENT_MIME_TYPES).concat(", "),
                  })
                ),
              vatCertificate: Yup.array().mime(
                Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
                t("error.general.mime", {
                  types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(
                    ", "
                  ),
                })
              ),
              gosiCertificate: Yup.array().mime(
                Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
                t("error.general.mime", {
                  types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(
                    ", "
                  ),
                })
              ),
            })}
            onSubmit={(
              values,
              { setStatus, setErrors, setFieldError, setSubmitting }
            ) => {
              const valuesKycKybonly = {
                companyConsent: values.companyConsent,
                shareholderConsent: values.shareholderConsent,
                articlesOfAssociation: values.articlesOfAssociation,
                vatCertificate: values.vatCertificate,
                gosiCertificate: values.gosiCertificate,
              };

              const filteredValues = {} as KycKybDocumentsValues;
              for (const [documentGroup, documents] of Object.entries(
                valuesKycKybonly
              )) {
                filteredValues[documentGroup as keyof KycKybValues] =
                  documents.map((document: PrivateFile) => document["@id"]!);
              }

              kycKybPost(
                {
                  companyIri: user?.company!,
                  values: filteredValues,
                },
                {
                  onError: (error) => {
                    if (error instanceof ViolationError) {
                      setStatus({
                        isValid: false,
                        msg: `${error.defaultErrorMsg}`,
                      });
                      setErrors(error.fields);
                    } else {
                      setStatus({
                        isValid: false,
                        msg: `${error.message}`,
                      });
                    }

                    setSubmitting(false);
                  },
                  onSuccess: async (data) => {
                    setCompany(data);
                    navigate(PATH_ONBOARDING.applicationOverview);
                  },
                }
              );
            }}
          >
            {({ values, errors, isSubmitting, isValid, status }) => (
              <Form>
                <PoWNotificationModal
                  opened={PoWModalOpened}
                  setOpened={setPoWModalOpened}
                />

                <Stack spacing={2} justifyContent="space-between">
                  <Box
                    sx={{
                      fontSize: "1.5em",
                      fontWeight: "600",
                      lineHeight: "2",
                    }}
                  >
                    {t("pages.deployment.simah_heading")}{" "}
                    <Box
                      component="img"
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "4em",
                        lineHeight: "4",
                        verticalAlign: "middle",
                      }}
                      src={`/images/simah.svg`}
                      alt="Simah"
                    />
                  </Box>
                  <Box sx={{ paddingLeft: "2em" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <Box>
                        <IconStatus
                          data={values.companyConsent}
                          form_error={false}
                        />
                      </Box>
                      <Box sx={{ width: "37.3%" }}>
                        <Typography variant="h5" sx={{ mb: 0.5 }}>
                          {t("pages.verification.company_consent.heading")}{" "}
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{
                              borderRadius: "6px",
                              padding: 1,
                              display: "inlineBlock",
                              boxSizing: "border-box",
                              minHeight: "32px",
                              textTransform: "capitalize",
                              color: "info.main",
                              width: "auto",
                              backgroundColor: (theme) =>
                                alpha(theme.palette.info.main, 0.12),
                            }}
                          >
                            {t("global.required")}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary", mb: 2 }}
                        >
                          {t("pages.verification.company_consent.subheading")}
                        </Typography>

                        <Link
                          href="/CustomerIndividualConsent.pdf"
                          target="_blank"
                          sx={{
                            fontSize: "0.75em",
                            color: "text.primary",
                            borderRadius: "2em",
                            border: "1px solid",
                            marginBottom: "5em",
                            padding: "0.5em 1em",
                            display: "inline-block",
                          }}
                        >
                          {t("global.download_copy")}
                        </Link>
                      </Box>
                      <Box sx={{ width: "57.6%" }}>
                        <UploadMultiFile
                          sxWrapper={{ mb: 3 }}
                          fieldName="companyConsent"
                          disabled={isSubmitting}
                          acceptedFileTypes={DEFAULT_CONSENT_MIME_TYPES}
                          filesLimit={20}
                          isInitialFiles={initialValuesKsa.companyConsent}
                        />
                      </Box>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <Box>
                        <IconStatus
                          data={values.shareholderConsent}
                          form_error={false}
                        />
                      </Box>
                      <Box sx={{ width: "37.3%" }}>
                        <Typography variant="h5" sx={{ mb: 0.5 }}>
                          {t("pages.verification.shareholder_consent.heading")}{" "}
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{
                              borderRadius: "6px",
                              padding: 1,
                              display: "inlineBlock",
                              boxSizing: "border-box",
                              minHeight: "32px",
                              textTransform: "capitalize",
                              color: "info.main",
                              width: "auto",
                              backgroundColor: (theme) =>
                                alpha(theme.palette.info.main, 0.12),
                            }}
                          >
                            {t("global.required")}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary", mb: 2 }}
                        >
                          {t(
                            "pages.verification.shareholder_consent.subheading"
                          )}
                        </Typography>

                        <Link
                          href="/CommercialConsent.pdf"
                          target="_blank"
                          sx={{
                            fontSize: "0.75em",
                            color: "text.primary",
                            borderRadius: "2em",
                            border: "1px solid",
                            marginBottom: "5em",
                            padding: "0.5em 1em",
                            display: "inline-block",
                          }}
                        >
                          {t("global.download_copy")}
                        </Link>
                      </Box>
                      <Box sx={{ width: "57.6%" }}>
                        <UploadMultiFile
                          sxWrapper={{ mb: 3 }}
                          fieldName="shareholderConsent"
                          disabled={isSubmitting}
                          acceptedFileTypes={DEFAULT_CONSENT_MIME_TYPES}
                          filesLimit={20}
                          isInitialFiles={initialValuesKsa.shareholderConsent}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Box>
                    <IconStatus
                      data={values.articlesOfAssociation}
                      form_error={false}
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t("pages.verification.articles_of_association.heading")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t(
                        "pages.verification.articles_of_association.subheading"
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <UploadMultiFile
                      sxWrapper={{ mb: 3 }}
                      fieldName="articlesOfAssociation"
                      disabled={isSubmitting}
                      acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                      filesLimit={20}
                      isInitialFiles={initialValuesKsa.articlesOfAssociation}
                    />
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Box>
                    <IconStatus
                      data={values.vatCertificate}
                      form_error={false}
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t("pages.verification.vat_certificate.heading")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t("pages.verification.vat_certificate.subheading")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <UploadMultiFile
                      sxWrapper={{ mb: 3 }}
                      fieldName="vatCertificate"
                      disabled={isSubmitting}
                      acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                      filesLimit={20}
                      isInitialFiles={initialValuesKsa.vatCertificate}
                    />
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Box>
                    <IconStatus
                      data={values.gosiCertificate}
                      form_error={false}
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t("pages.verification.gosi_certificate.heading")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t("pages.verification.gosi_certificate.subheading")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <UploadMultiFile
                      sxWrapper={{ mb: 3 }}
                      fieldName="gosiCertificate"
                      disabled={isSubmitting}
                      acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                      filesLimit={20}
                      isInitialFiles={initialValuesKsa.gosiCertificate}
                    />
                  </Box>
                </Stack>

                {status && (
                  <Alert severity="error" variant="outlined" sx={{ mb: 3 }}>
                    {status.msg}
                  </Alert>
                )}

                {isSubmitting && (
                  <Box sx={{ mb: 3 }}>
                    <LinearProgress />
                  </Box>
                )}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    id="back-button"
                    type="submit"
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => {
                      navigate(PATH_ONBOARDING.verification);
                    }}
                  >
                    {t("global.back")}
                  </Button>
                  <Button
                    id="continue-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="1em" sx={{ color: "#fff" }} />
                      ) : (
                        <></>
                      )
                    }
                    disabled={isSubmitting || !isValid}
                  >
                    {t("global.continue")}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Card>
    </Container>
  );
};

export default AdditionalDocumentsKSA;
