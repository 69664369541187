import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import Page from "../Page";
import { Container } from "@mui/material";
import { PATH_DEPLOYMENT } from "../Routes/paths";
import DeploymentStep, { DeploymentStepsEnum } from "../DeploymentStep";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const DeploymentPages = () => {
  const { t } = useTranslation();
  const { setTitle } = useTitle();
  const location = useLocation();
  const { company } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(t("pages.deployment.title"));
  }, [setTitle, t]);

  if (
    company?.countryCode === "SA" &&
    location.pathname === PATH_DEPLOYMENT.submitKyc
  ) {
    navigate(PATH_DEPLOYMENT.signContract);
    return null;
  }

  let deploymentStep: DeploymentStepsEnum;
  if (location.pathname === PATH_DEPLOYMENT.acceptOffer) {
    deploymentStep = DeploymentStepsEnum.ACCEPT_OFFER;
  } else if (location.pathname === PATH_DEPLOYMENT.submitKyc) {
    deploymentStep = DeploymentStepsEnum.SUBMIT_KYC;
  } else {
    deploymentStep = DeploymentStepsEnum.SIGN_CONTRACT;
  }

  return (
    <Page title={t("pages.deployment.title")}>
      <Container sx={{ my: 5 }}>
        <DeploymentStep step={deploymentStep} />
        <Outlet />
      </Container>
    </Page>
  );
};

export default DeploymentPages;
