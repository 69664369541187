import {
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
  TextField as MuiTextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik, FormikProps } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  RevenueOptionItemType,
  VerifyBusinessInfoValues,
} from "../../types/VerifyBusinessInfoValues";
import { Autocomplete, AutocompleteRenderInputParams } from "formik-mui";
import { isLngAR } from "../../utils/i18n";
import ViolationError from "../../utils/violationError";
import { useVerifyBusinessInfoPost } from "../../queries/businessInfo";
import useAuth from "../../hooks/useAuth";
const SHOW_ERROR_TIMEOUT_MS = 5000;
export const generateRevenueOptions = () => {
  const options = [
    { label: "", min: 0, max: 0 },
    { label: "10k-200k", min: 10, max: 200 },
    { label: "200k-500k", min: 200, max: 500 },
    { label: "500k-1m", min: 500, max: 1000 },
    { label: "1m+", min: 1000, max: Infinity },
  ];

  return options.map((option, index) => {
    const value = option.max === Infinity ? option.min * 2 : option.max;
    const possibleFinancing =
      option.max === Infinity
        ? option.min * 1.5
        : (option.min + option.max) / 2;

    return {
      label: option.label,
      value: value,
      possibleFinancing: possibleFinancing,
    };
  });
};
export const revenueOptions: RevenueOptionItemType[] = generateRevenueOptions();
export const getRevenueOptionDetails = (value: number) => {
  const result = revenueOptions.find((option) => option.value === value);
  return result ?? revenueOptions[0];
};

export type VerifyBusinessFormsExtendedType = {
  currentPage: number;
  handleChangePage: (new_page: number) => void;
  updateEligibleRevenue: (possible_financing: number) => void;
  monthlyRevenue: number;
};

const VerifyBusinessInfoForm = ({
  handleChangePage,
  currentPage,
  updateEligibleRevenue,
  monthlyRevenue,
}: VerifyBusinessFormsExtendedType) => {
  const { t } = useTranslation(["translation", "countries"]);
  const formikRef = useRef<FormikProps<VerifyBusinessInfoValues>>(null);
  const { mutate: verifyBusinessInfo } = useVerifyBusinessInfoPost();
  const { company, user, refetchCompany, refetchUser } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [dropshipProduct, setDropshipProduct] = useState<boolean>(
    company?.["isDropshipProduct"] ?? false
  );
  const [authPerson, setAuthPerson] = useState<boolean>(
    user?.["isAuthorizedOwner"] ?? false
  );
  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), SHOW_ERROR_TIMEOUT_MS);
    }
  }, [error]);
  const handleChange = (event: VerifyBusinessInfoValues) => {
    if (event.revenue) {
      if (event.revenue.value) {
        updateEligibleRevenue(event.revenue.value);
      }
    }
  };
  const formatLabel = (label: string, isArabic: boolean) => {
    let currency = user?.countryCode === "SA" ? "SAR" : "AED";
    if (label) {
      if (isArabic) {
        return `\u202A${currency} ${label}\u202C`;
      }
      return `${currency} ${label}`;
    }
    return label;
  };

  const formattedRevenueOptions = revenueOptions.map((option) => ({
    ...option,
    label: formatLabel(option.label, isLngAR()),
  }));

  const handleDropshipProductChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDropshipProduct(event.target.value === "yes");
  };
  const handleAuthPersonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAuthPerson(event.target.value === "yes");
  };

  return (
    <>
      <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
        {t("pages.choose_revenue_details.heading")}
      </Typography>
      <Formik
        innerRef={formikRef}
        validateOnMount
        initialValues={
          {
            revenue: getRevenueOptionDetails(company?.monthlyRevenue ?? 0),
          } as VerifyBusinessInfoValues
        }
        validationSchema={Yup.object().shape({
          revenue: Yup.object()
            .nullable()
            .required(t("error.company.monthly_revenue.required")),
        })}
        onSubmit={({ revenue }, { setSubmitting, resetForm }) => {
          if (revenue.value) {
            updateEligibleRevenue(revenue.value);
            verifyBusinessInfo(
              {
                dropship_product: dropshipProduct,
                auth_person: authPerson,
                revenue: revenue.value as number,
              },
              {
                onError: (error) => {
                  if (error instanceof ViolationError) {
                    setError(`${error.defaultErrorMsg}`);
                  } else {
                    setError(`${error.message}`);
                  }
                },
                onSuccess: (data) => {
                  if (data.success) {
                    refetchCompany();
                    refetchUser();
                    handleChangePage(currentPage + 1);
                  } else {
                    setError(t("error.unexpected"));
                  }
                },
                onSettled: () => {
                  setSubmitting(false);
                  updateEligibleRevenue(0);
                },
              }
            );
          } else {
            setSubmitting(false);
            setError(t("error.company.monthly_revenue.required"));
          }
        }}
      >
        {({
          isValid,
          isSubmitting,
          touched,
          errors,
          values,
          setSubmitting,
        }) => (
          <Form>
            {error && (
              <Box sx={{ my: 3 }}>
                <FormHelperText error sx={{ textAlign: "left" }}>
                  {error}
                </FormHelperText>
              </Box>
            )}
            <Stack sx={{ mb: 3 }} spacing={3}>
              <Field
                id="field-revenue"
                name="revenue"
                component={Autocomplete}
                options={formattedRevenueOptions}
                getOptionLabel={(option: RevenueOptionItemType) => option.label}
                sx={{ mb: 3 }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <MuiTextField
                    {...params}
                    name="revenue"
                    variant="standard"
                    label={t("global.monthly_revenue_question")}
                    fullWidth
                    error={touched["revenue"] && !!errors["revenue"]}
                    helperText={touched["revenue"] && errors["revenue"]}
                    disabled={isSubmitting}
                  />
                )}
              />
              {handleChange(values)}
            </Stack>
            <Stack>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", mb: 2 }}
              >
                {t("pages.verify_your_business_info.dropship_product_question")}
              </Typography>
              <FormControl onChange={handleDropshipProductChange}>
                <MuiRadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={"no"}
                    checked={dropshipProduct === false}
                    control={
                      <Radio
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "gray" },
                        }}
                      />
                    }
                    label={t<string>("global.no")}
                  />
                  <FormControlLabel
                    value={"yes"}
                    checked={dropshipProduct === true}
                    control={
                      <Radio
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "gray" },
                        }}
                      />
                    }
                    label={t<string>("global.yes")}
                  />
                </MuiRadioGroup>
              </FormControl>
            </Stack>
            <Stack>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", my: 2 }}
              >
                {t("pages.verify_your_business_info.authorization_inquiry")}
              </Typography>
              <FormControl onChange={handleAuthPersonChange}>
                <MuiRadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={"no"}
                    checked={authPerson === false}
                    control={
                      <Radio
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "gray" },
                        }}
                      />
                    }
                    label={t<string>("global.no")}
                  />
                  <FormControlLabel
                    value={"yes"}
                    checked={authPerson === true}
                    control={
                      <Radio
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "gray" },
                        }}
                      />
                    }
                    label={t<string>("global.yes")}
                  />
                </MuiRadioGroup>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 6 }}
            >
              <Button
                id="back-button"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => handleChangePage(currentPage - 1)}
              >
                {t("global.back")}
              </Button>
              <Button
                id="register-button"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting || !monthlyRevenue}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size="1.2rem" sx={{ color: "#fff" }} />
                  ) : (
                    <></>
                  )
                }
              >
                {t("global.continue")}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VerifyBusinessInfoForm;
